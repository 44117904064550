



















































































































import Vue from "vue";
import * as models from "@/models";
import Moment from "moment";
import { G, util } from "@/globals";
import { Tracking } from "@/code/tracking";

export default {
  name: "jobCard",
  props: {
    jobPost: {
      type: Object as () => models.jobPostExtended
    },
    bookmarkVisible: {
      type: Boolean,
      default: true,
      required: false
    },
    shareVisible: {
      type: Boolean,
      default: false,
      required: false
    },
    applyVisible: {
      type: Boolean,
      default: true
    },
    metaVisible: {
      type: Boolean,
      default: false,
      required: false
    },
    dark: {
      type: Boolean, //Object as () => Boolean | String,
      required: false,
      default: false
    },
    detailsVisible: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data: () => ({}),
  created: function() {
    Moment.defaultFormat = "DD.MM.YYYY HH:mm";
  },
  methods: {
    apply: function() {
      this.$emit("apply", this.jobPost);
    },
    bookmark: function() {
      this.$emit("bookmark", this.jobPost);
    },
    cardClick: function() {
      this.$emit("card-click", this.jobPost);
    },
    Moment: function() {
      return Moment();
    },
    formatDate: function(
      value: string,
      calendar: boolean,
      time?: boolean
    ): string {
      // let defaultFormat = `DD/MMM/YYYY ${time ? "HH:mm" : ""}`;
      // let defaultFormat = `DD-MMM ${time ? "HH:mm" : ""}`;
      if (!value) return "";
      let defaultFormat = `D MMMM ${time ? "(hh:mm a)" : ""}`;
      let v: Moment.Moment;

      v = Moment(time ? value : value.split("T")[0]);
      // if (!time) v = v.startOf("day");
      if (!calendar) return v.format(defaultFormat);
      return v.calendar(null, {
        sameElse: defaultFormat
      });
    },
    shareJobPost: function() {
      util.share(
        this.jobPost.title + " | " + this.jobPost.industry.title,
        this.buildShareUrl()
      );
      Tracking.jobPost.share(this.jobPost.id);
    },
    copyJobPostToClipboard: function() {
      let linkText = `${this.jobPost.title} | ${
        this.jobPost.industry.title
      } | MeInOne Link: ${this.buildShareUrl()}`;

      this.$clipboard(linkText);
      G.showSnackbar("Link copied to clipboard", "success");
      Tracking.jobPost.copyLink(this.jobPost.id);
    },
    buildShareUrl: function(): string {
      let replaceAll = (str: string, find: string, replace: string) => {
        return str.replace(new RegExp(find, "g"), replace);
      };
      let jp: models.jobPostExtended = this.jobPost;
      let infoInUrl = `${jp.organization.name}/${jp.title}/${jp.city.id}`
        .replace(/[^a-z0-9 /]/gi, "")
        .toLowerCase();

      console.log(infoInUrl);
      infoInUrl = replaceAll(infoInUrl, "-", "");
      infoInUrl = replaceAll(infoInUrl.replace(/  +/g, " "), " ", "-");
      console.log(infoInUrl);

      let link = `http://meinone-sharer.azurewebsites.net/jobpost.aspx?id=${this.jobPost.id}&job=${infoInUrl}`;

      return link;
    }
  },
  computed: {
    // jobPostExtended: function(): models.jobPostExtended {
    //   if (this.jobPost) return new models.jobPostExtended(this.jobPost);
    //   else return null;
    // }

    seekerContext: function(): models.jobPostSeekerContext {
      let jp: models.jobPostExtended = this.jobPost;
      if (!jp.context || !jp.context.seeker) return {};
      return jp.context.seeker;
    },
    darkMode: function(): boolean {
      return !(this.dark === undefined || this.dark == false);
    },
    IsShareSupported: () => util.isShareSupported
  },
  watch: {},
  components: {}
};
