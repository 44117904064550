var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.jobPost
        ? _c(
            "v-card",
            _vm._b(
              {
                staticClass: "elevation-17",
                class: [
                  _vm.darkMode ? "white--text" : "primary--text",
                  _vm.darkMode ? "primary" : "white"
                ],
                attrs: { dark: !_vm.darkMode, light: _vm.darkMode }
              },
              "v-card",
              _vm.$attrs,
              false
            ),
            [
              _vm.jobPost.listingDate
                ? _c(
                    "span",
                    { staticClass: "font-weight-light card-label right" },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.formatDate(_vm.jobPost.listingDate, true, true)
                          ) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "v-card-title",
                {
                  attrs: { "primary-title": "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.cardClick($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "title xwhite--text" },
                    [
                      _c(
                        "span",
                        { staticClass: "accent--text text--darken-2" },
                        [_vm._v(_vm._s(_vm.jobPost.title))]
                      ),
                      _vm.jobPost.industry
                        ? _c("v-subheader", {
                            staticClass: "pa-0 primary--text font-weight-light",
                            staticStyle: {
                              height: "auto",
                              "line-height": "1.8"
                            },
                            domProps: {
                              textContent: _vm._s(_vm.jobPost.industry.title)
                            }
                          })
                        : _vm._e(),
                      _c(
                        "v-subheader",
                        {
                          staticClass: "pa-0 primary--text",
                          staticStyle: { height: "auto", "line-height": "1" }
                        },
                        [_vm._v(_vm._s(_vm.jobPost.organization.name))]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-slide-y-transition",
                [
                  _vm.detailsVisible
                    ? _c(
                        "v-card-text",
                        {
                          staticClass: "pt-0",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.cardClick($event)
                            }
                          }
                        },
                        [
                          _c("p", { staticClass: "xline-clamp-3" }, [
                            _vm._v(_vm._s(_vm.jobPost.description))
                          ]),
                          _vm.metaVisible
                            ? [
                                _c("v-divider", { staticClass: "my-2" }),
                                _c(
                                  "div",
                                  { staticClass: "xgrey--text xtext-xs-right" },
                                  [
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-light" },
                                        [_vm._v("Created")]
                                      ),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.formatDate(
                                              _vm.jobPost.creationDate,
                                              true,
                                              false
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ]),
                                    _vm.jobPost.listingDate
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "font-weight-light"
                                            },
                                            [_vm._v("Listed")]
                                          ),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.formatDate(
                                                  _vm.jobPost.listingDate,
                                                  true,
                                                  true
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.jobPost.expiryDate
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "font-weight-light"
                                            },
                                            [_vm._v("Expiry")]
                                          ),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.formatDate(
                                                  _vm.jobPost.expiryDate,
                                                  true,
                                                  true
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._t(
                "card-actions",
                [
                  _c(
                    "v-card-actions",
                    { staticClass: "grey lighten-3 xsecondary white--text" },
                    [
                      _vm.bookmarkVisible
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "", color: "primary", flat: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.bookmark($event)
                                }
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  _vm._s(
                                    _vm.seekerContext.bookmarked
                                      ? "bookmark"
                                      : "bookmark_border"
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.shareVisible
                        ? [
                            _vm.IsShareSupported
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      color: "primary",
                                      flat: ""
                                    },
                                    on: { click: _vm.shareJobPost }
                                  },
                                  [_c("v-icon", [_vm._v("share")])],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", color: "primary", flat: "" },
                                on: { click: _vm.copyJobPostToClipboard }
                              },
                              [_c("v-icon", [_vm._v("library_books")])],
                              1
                            )
                          ]
                        : _vm._e(),
                      !_vm.jobPost.isListed
                        ? _c(
                            "v-chip",
                            {
                              attrs: {
                                disabled: "",
                                outline: "",
                                color: "default",
                                small: ""
                              }
                            },
                            [_vm._v("NOT LISTED")]
                          )
                        : _vm._e(),
                      _vm.jobPost.isListed && _vm.jobPost.isExpired
                        ? _c(
                            "v-chip",
                            {
                              attrs: {
                                disabled: "",
                                outline: "",
                                color: "warning",
                                small: ""
                              }
                            },
                            [_vm._v("EXPIRED")]
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _vm._t(
                        "main-action",
                        [
                          _vm.applyVisible && !_vm.seekerContext.application
                            ? _c(
                                "v-btn",
                                {
                                  class: [
                                    !_vm.darkMode
                                      ? "white--text primary"
                                      : "primary--text"
                                  ],
                                  attrs: { roundx: "" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.apply($event)
                                    }
                                  }
                                },
                                [_vm._v("Apply")]
                              )
                            : _vm._e(),
                          !!_vm.seekerContext.application
                            ? _c(
                                "v-tooltip",
                                { attrs: { left: "", xcolor: "secondary" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { slot: "activator", icon: "" },
                                      slot: "activator"
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "secondary--text" },
                                        [_vm._v("check_circle")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      "You've applied to this job " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            _vm.seekerContext.application
                                              .creationDate,
                                            "from",
                                            "now"
                                          )
                                        ) +
                                        ". Good luck!"
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        { jobPost: _vm.jobPost }
                      )
                    ],
                    2
                  )
                ],
                { item: _vm.jobPost }
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }